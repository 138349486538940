import { Component, BaseComponent, Prop } from '@zento-lib/components';

import { Link } from '../../../atom/Link/Link';

import { ISearchCategories } from './SearchCategories.d';
import style from './style.scss';

/**
 * Search Categories
 *
 * Search Categories component showing categories breadcrumbs.
 **/
@Component({})
export class SearchCategories extends BaseComponent<ISearchCategories, unknown> {
  private static T = {
    categories: 'header_search_categories',
  };

  /**
   * Determines all categories
   */
  @Prop({ type: Array, required: true })
  categories: Array<{ name: string; url: string }>;

  render() {
    return (
      <div class={style.categories}>
        <h4>{this.getTranslation({ id: SearchCategories.T.categories })}</h4>
        <div class={style.categoriesHandler}>
          {this.categories.map((category) => {
            return (
              <Link to={'/' + category.url} styleType='secondary'>
                <span class={style.categoryLink}>
                  <span>{category.name}</span>
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}
